import { base_url } from "../../../base";
import axios from "axios";

export const GET_SUCCESS_DEPARTMENT_ASSIGN = "GET_SUCCESS_DEPARTMENT_ASSIGN";
export const GET_SUCCESS_TEAM_ASSIGN = "GET_SUCCESS_TEAM_ASSIGN";
export const GET_SUCCESS_EXECUTIVE_ASSIGN = "GET_SUCCESS_EXECUTIVE_ASSIGN";

const getDepartmentAssign = (assignDepartmentArr) => ({
    type: GET_SUCCESS_DEPARTMENT_ASSIGN,
    payload: assignDepartmentArr,
});
const getTeamAssign = (assignTeamArr) => ({
    type: GET_SUCCESS_TEAM_ASSIGN,
    payload: assignTeamArr,
});
const getExeAssign = (assignExeArr) => ({
    type: GET_SUCCESS_EXECUTIVE_ASSIGN,
    payload: assignExeArr,
});

export function getDepartmentAssignActions(authState, authRole) {
    let auth_role = authRole === 1 ? `api/v1/department/list` : `api/v1/department/executive/get/${authState}`;
    const cacheBuster = new Date().getTime();
    return dispatch => {
        axios
            .get(`${base_url()}/${auth_role}`, {
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                    'If-Modified-Since': '0',
                },
                withCredentials: true,
                credentials: 'include',
                params: {
                    cache: cacheBuster,
                }
            })
            .then(response => {
                response.data.status ? dispatch(getDepartmentAssign(response.data.message)) : dispatch(getDepartmentAssign([]))
            }).catch(error => console.log(error))
    }
}

export function getTeamAssignActions(departId) {
    const cacheBuster = new Date().getTime();
    return dispatch => {
        axios
            .get(`${base_url()}/api/v1/team/department/get/${departId}`, {
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                    'If-Modified-Since': '0',
                },
                withCredentials: true,
                credentials: 'include',
                params: {
                    cache: cacheBuster,
                }
            })
            .then(response => {
                response.data.status ? dispatch(getTeamAssign(response.data.result)) : dispatch(getTeamAssign([]))
            }).catch(error => console.log(error))
    }
}

export function getExecutiveAssignActions(teamID) {
    const cacheBuster = new Date().getTime();
    return dispatch => {
        axios
            .get(`${base_url()}/api/v1/executive/team/get/${teamID}`, {
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                    'If-Modified-Since': '0',
                },
                withCredentials: true,
                credentials: 'include',
                params: {
                    cache: cacheBuster,
                }
            })
            .then(response => {
                response.data.status ? dispatch(getExeAssign(response.data.result)) : dispatch(getExeAssign([]))
            }).catch(error => console.log(error))
    }
}