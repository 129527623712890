import { base_url, toast } from "../../../base";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";

export const INSERT_SUCCESS_DESIGNATION = "INSERT_SUCCESS_DESIGNATION";
export const GET_SUCCESS_DESIGNATION = "GET_SUCCESS_DESIGNATION";

export function insertDesignationActions(userData, userId) {
    return dispatch => {
        const data = {
            owner_id: userId.owner_id,
            designation_name: userData.designation_name
        }
        axios
            .post(`${base_url()}/api/v1/designation/add`, data, { withCredentials: true, credentials: 'include' })
            .then(response => {
                if (response.data.status) {
                    dispatch({
                        type: INSERT_SUCCESS_DESIGNATION,
                        data: response,
                    })
                    toast.success(response.data.message);
                } else {
                    toast.error(response.data.message);
                }
            }).catch(error => {
                toast.error(error.response.data.message)
            })
    }
}

const getDesignation = (desArr) => ({
    type: GET_SUCCESS_DESIGNATION,
    payload: desArr
});
export function getDesignationActions() {
    const cacheBuster = new Date().getTime();
    return dispatch => {
        axios
            .get(`${base_url()}/api/v1/designation/list`, {
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                    'If-Modified-Since': '0',
                },
                withCredentials: true,
                credentials: 'include',
                params: {
                    cache: cacheBuster,
                }
            })
            .then(response => {
                dispatch(getDesignation(response.status === 204 ? [] : response.data.message))
            }).catch(error => {
                if (error.response.status === 404 || error.response.status === 500) {
                    dispatch(getDesignation([]))
                } else if (error.response.status === 401) {
                    toast.error(error.response.data)
                }
            });
    };
}