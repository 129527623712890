import { base_url, toast } from "../../../base";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";

export const GET_SUCCESS_DROPDOWN = "GET_SUCCESS_DROPDOWN";

const getDropdown = (identifier, dropdownArray) => ({
    type: GET_SUCCESS_DROPDOWN,
    payload: { identifier, dropdownArray },
});
export function getDropdownActions(identifier, URL, inputValue, keyValue, keyLabel, keyLastLabel) {
    const cacheBuster = new Date().getTime();
    return dispatch => {
        axios
            .get(`${base_url()}/${URL}`, {
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                    'If-Modified-Since': '0',
                },
                withCredentials: true,
                credentials: 'include',
                params: { search: inputValue, cache: cacheBuster },
            })
            .then(response => {
                if (response.status === 204) {
                    dispatch({
                        type: GET_SUCCESS_DROPDOWN,
                        payload: { identifier, dropdownArray: [] }, // Empty array or handle 204 status as needed
                    });
                } else {
                    const dropdownArray = response.data.message.map(result => ({
                        value: result[keyValue],
                        label: `${result[keyLabel]} ${keyLastLabel ? result[keyLastLabel] || "" : ""}`,
                    }));
                    dispatch({
                        type: GET_SUCCESS_DROPDOWN,
                        payload: { identifier, dropdownArray },
                    });
                }
            }).catch(error => {
                if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: GET_SUCCESS_DROPDOWN,
                        payload: { identifier, dropdownArray: [] },
                    });
                } else if (error.response.status === 401) {
                    toast.error(error.response.data)
                }
            })
    }
}