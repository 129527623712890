import React, { Suspense, useState, useEffect } from "react";
import "../styles/app/app.scss";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import AppContext from "./appContext";
import routes from "./RootRoutes";
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import { Store, persistor } from "./redux/Store";
import { renderRoutes } from "react-router-config";
import Auth from "./auth/Auth";
import RootRoutes from "./RootRoutes";
import { Loading } from "@gull";
import { PersistGate } from 'redux-persist/integration/react'
import { ToastContainer, Flip, history } from "../base"
import './custom.css';
import socket from "./views/socket";

function App() {
  if (process.env.NODE_ENV === 'production') {
    disableReactDevTools();
  }
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    socket.emit("isOffline", isOnline)

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [isOnline]);

  return (
    <AppContext.Provider value={{ routes }}>
      <Provider store={Store}>
        <Auth>
          <PersistGate loading={<Loading />} persistor={persistor} >
            {isOnline ? (<Suspense fallback={<Loading />}>
              <Router history={history} >{renderRoutes(RootRoutes)}</Router>
            </Suspense>) : (<div style={{ textAlign: 'center', paddingTop: '50px', fontSize: "x-large", fontFamily: "math" }}>
              <img src='/assets/no-internet.svg' alt='no-internet.svg' />
            </div>)}
          </PersistGate>
        </Auth>
      </Provider>
      <ToastContainer transition={Flip} position="top-center" autoClose={1500} hideProgressBar={false} closeOnClick={false} pauseOnHover={false} draggable={false} />
    </AppContext.Provider>
  );
}
export default App;