import React, { memo } from 'react'
import { Form, Row, Col } from "../../../base";

const MySingleImage = ({ name, label, accept, onChange, showImage }) => {
    return <Row className="d-flex justify-content-end">
        <Col sm="2">
            <Form.Label>{label}</Form.Label>
        </Col>
        <Col sm="10" className='mb-2'>
            <input type='file' name={name} accept={accept} onChange={onChange} className="form-control" />
            {showImage}
        </Col>
    </Row>
}

export default memo(MySingleImage)