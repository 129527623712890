import { base_url, toast } from "../../../base";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";

export const GET_ORDER_TYPE = "GET_ORDER_TYPE";
export const GET_CONFIG_TYPE = "GET_CONFIG_TYPE";
// export const GET_FEEDBACK = "GET_FEEDBACK";

const getOrderType = (orderTypeArr) => ({
    type: GET_ORDER_TYPE,
    payload: orderTypeArr,
});

export function getOrderTypeActions(flagType) {
    let authType = flagType.flag === 1 ? `api/v1/type/task/list` : `get_order_type`;
    const cacheBuster = new Date().getTime();
    return dispatch => {
        axios
            .get(`${base_url()}/${authType}`, {
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                    'If-Modified-Since': '0',
                },
                withCredentials: true,
                credentials: 'include',
                params: {
                    cache: cacheBuster,
                }
            })
            .then(response => {
                dispatch(getOrderType(response.status === 204 ? [] : response.data.message))
            }).catch(error => {
                if (error.response.status === 404 || error.response.status === 500) {
                    dispatch(getOrderType([]))
                } else if (error.response.status === 401) {
                    toast.error(error.response.data)
                }
            })
    }
}

const getConfigType = (type) => ({
    type: GET_CONFIG_TYPE,
    payload: type,
});
export function getConfigTypeActions(ID) {
    const cacheBuster = new Date().getTime();
    return dispatch => {
        axios
            .get(`${base_url()}/api/v1/numbering/config/list/${ID}`, {
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                    'If-Modified-Since': '0',
                },
                withCredentials: true,
                credentials: 'include',
                params: {
                    cache: cacheBuster,
                }
            })
            .then(response => {
                dispatch(getConfigType(response.status === 204 ? [] : response.data.message))
            }).catch(error => {
                if (error.response.status === 404 || error.response.status === 500) {
                    dispatch(getConfigType([]))
                } else if (error.response.status === 401) {
                    toast.error(error.response.data)
                }
            })
    }
}