import React, { memo } from 'react'

const MySingleInput = ({ name, label, value, onChange, error, helperText, disabled, showStar, type, flag, placeholder }) => {
    return <>
        {flag != 1 && <label>{label}{showStar && <span className="text-danger">*</span>}</label>}
        <input type={type || "text"} className={`form-control ${error ? 'is-invalid' : 'mb-1'}`} placeholder={placeholder || label} disabled={disabled || false} onChange={onChange} value={value || ""} name={name} />
        {error && (<small className="text-danger">{helperText}</small>)}
    </>
}

export default memo(MySingleInput);