import { base_url, toast } from "../../../base";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";

export const FETCH_AUTH_DOCUMENT_LIST = " FETCH_AUTH_DOCUMENT_LIST";

const documentList = (list) => ({
    type: FETCH_AUTH_DOCUMENT_LIST,
    payload: list,
});

export function documentListActions(key) {
    const cacheBuster = new Date().getTime();
    return dispatch => {
        axios
            .get(`${base_url()}/api/v1/authentication/${key}`, {
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                    'If-Modified-Since': '0',
                },
                withCredentials: true,
                credentials: 'include',
                params: {
                    cache: cacheBuster,
                }
            })
            .then(response => {
                dispatch(documentList(response.status === 204 ? [] : response.data.message))
            }).catch(error => {
                if (error.response.status === 404 || error.response.status === 500) {
                    dispatch(documentList([]))
                } else if (error.response.status === 401) {
                    toast.error(error.response.data)
                }
            })
    }
}