import { base_url, toast, history } from "../../../base";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";

export const INSERT_SUCCESS_CONTACT = "INSERT_SUCCESS_CONTACT";
export const GET_CONTACT = "GET_CONTACT";
export const GET_ADMIN_CONTACT = "GET_ADMIN_CONTACT";

export function insertContactActions(userData, selectVal, userId) {
    userData.lead_source = selectVal
    userData.owner_id = userId?.owner_id
    userData.account_id = userId?.account_id
    return dispatch => {
        axios
            .post(`${base_url()}/api/v1/contact/add`, userData, { withCredentials: true, credentials: 'include' })
            .then(response => {
                if (response.data.status) {
                    dispatch({
                        type: INSERT_SUCCESS_CONTACT,
                        data: response,
                    })
                    userId?.flag == 0 && history.push("/contacts");
                    toast.success(response.data.message);
                } else {
                    toast.error(response.data.message);
                }
            }).catch(error => {
                toast.error(error.response.data.message)
            })
    }
}

const getContact = (conArr) => ({
    type: GET_CONTACT,
    payload: conArr
});

export function getContactActions(authState, authRole, query, page, limit) {
    // let authContact = authRole === 1 ? `api/v1/contact/list` : `show_contact/${authState}`
    const cacheBuster = new Date().getTime();
    return dispatch => {
        axios
            .get(`${base_url()}/api/v1/contact/list?search_query=${query}&page=${page}&limit=${limit}`, {
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                    'If-Modified-Since': '0',
                },
                withCredentials: true,
                credentials: 'include',
                params: {
                    cache: cacheBuster,
                }
            })
            .then(response => {
                if (response.status === 204) {
                    dispatch(getContact({ status: false, message: [], messageAllCount: 0, totalAllPage: 0 }))
                } else {
                    dispatch(getContact(response.data))
                }
            }).catch(error => {
                if (error.response.status === 404 || error.response.status === 500) {
                    dispatch(getContact({ status: false, message: [] }))
                } else if (error.response.status === 401) {
                    toast.error(error.response.data)
                }
            })
    }
}

const getAdminContact = (conAdminArr) => ({
    type: GET_ADMIN_CONTACT,
    payload: conAdminArr,
});

export function getAdminContactActions() {
    const cacheBuster = new Date().getTime();
    return dispatch => {
        axios
            .get(`${base_url()}/api/v1/contact/option/list`, {
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                    'If-Modified-Since': '0',
                },
                withCredentials: true,
                credentials: 'include',
                params: {
                    cache: cacheBuster,
                }
            })
            .then(response => {
                dispatch(getAdminContact(response.status === 204 ? [] : response.data.message))
            }).catch(error => {
                if (error.response.status === 404 || error.response.status === 500) {
                    dispatch(getAdminContact({ status: false, message: [] }))
                } else if (error.response.status === 401) {
                    toast.error(error.response.data)
                }
            })
    }
}