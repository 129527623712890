import React, { memo } from 'react'
import { Row, Col, Form } from "../../../base";

const MyInput = React.forwardRef(({ label, placeholder, error, helperText, showStar, ...props }, ref) => (
    <Col md={6} sm={12}>
        <Form.Group>
            <Row className="d-flex justify-content-end">
                <Col md="4" >
                    <Form.Label>
                        {label}{showStar ? <span className="text-danger">*</span> : null}
                    </Form.Label>
                </Col>
                <Col md="8" className='mb-2'>
                    <Form.Control {...props} isInvalid={error} placeholder={placeholder || label} />
                    {helperText && <Form.Control.Feedback type="invalid">{helperText}</Form.Control.Feedback>}
                </Col>
            </Row>
        </Form.Group>
    </Col>
));
export default memo(MyInput);