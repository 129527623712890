import history from "@history.js";
import { base_url } from "../../../base"

export const SET_USER_DATA = "USER_SET_DATA";
export const REMOVE_USER_DATA = "USER_REMOVE_DATA";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";

export function setUserData(user) {
  return dispatch => {
    dispatch({
      type: SET_USER_DATA,
      data: user
    });
  };
}

export function logoutUser() {
  return dispatch => {
    fetch(`${base_url()}/authlogout`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
      .then(response => response.json())
      .then(data => {
        if (data.status) {
          history.push({ pathname: "/signin" });
        } else {
          history.push({ pathname: "/board" });
        }
      })
      .catch((error) => {
        history.push({ pathname: "/" });
      });
    dispatch({ type: USER_LOGGED_OUT });
  };
}
