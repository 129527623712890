import { GET_SUCCESS_DROPDOWN } from "../actions/SearchDropdownAction";

const initialState = {
    searchDropdown: {
        account: [],
        product: [],
        lead: [],
        contact: [],
        opportunity: [],
    },
    success: false,
    loading: true,
};


const SearchDropdownReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_SUCCESS_DROPDOWN:
            return {
                ...state,
                searchDropdown: {
                    ...state.searchDropdown,
                    [action.payload.identifier]: action.payload.dropdownArray,
                },
                loading: false
            }
        default:
            return state;
    }

}

export default SearchDropdownReducer;