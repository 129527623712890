import { lazy } from "react";

const Signup = lazy(() => import("./Signup"));
const OTP = lazy(() => import("./otp_message"));
const Signin = lazy(() => import("./Signin"));
const Logout = lazy(() => import("./Logout"));
const ForgotPassword = lazy(() => import("./ForgotPassword"));
const Error404 = lazy(() => import("./Error"));
const Error401 = lazy(() => import("./Error401"));
const Dash = lazy(() => import("./Dash"));
const ReplyTicket = lazy(() => import("./ReplyTicket"));
const Chat = lazy(() => import("./ChatSystem"));
const Event = lazy(() => import("./EventCalendar"));
const EmailOpportunity = lazy(() => import("./EmailOpportunity"));
const Quotation = lazy(() => import("./Quotation"));
const OrderEmail = lazy(() => import("./OrderEmail"));
const SaleInvoice = lazy(() => import("./SaleInvoice"));
const ChangePassword = lazy(() => import("./CustomChangePassword"));

const sessionsRoutes = [
  // { path: "/signup", component: Signup },
  { path: "/otp_sent", component: OTP },
  { path: "/signin", component: Signin },
  { path: "/forgot-password", component: ForgotPassword },
  { path: "/logout", component: Logout },
  { path: "/404", component: Error404 },
  { path: "/401", component: Error401 },
  { path: "/board", component: Dash },
  { path: "/feedback_ticket", component: ReplyTicket },
  { path: "/chatsystem", component: Chat },
  { path: "/event_calendar", component: Event },
  { path: "/saleinvoice", component: EmailOpportunity },
  { path: "/orderinvoice", component: OrderEmail },
  { path: "/quotationinvoice", component: Quotation }, // no use of the component
  { path: "/invoicesale", component: SaleInvoice }, // no use of the component
  { path: "/change-password", component: ChangePassword },
];

export default sessionsRoutes;
